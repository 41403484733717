<script setup lang="ts">
import { useNotificationStore } from '~/stores/notification';
import { storeToRefs } from 'pinia';
import type { Notification } from '~/types'; 

const notificationStore = useNotificationStore(); // Access the notification store
const { notifications, loading } = storeToRefs(notificationStore); // Extract notifications and loading state as reactive refs

const { isNotificationsSlideoverOpen } = useDashboard();
const selectedNotification = ref<Notification | null>(null);
const showContextMenu = ref(false);
const contextMenuStyle = ref({ top: '0px', left: '0px' });
const contextNotification = ref<Notification | null>(null);

// Function to show the context menu
const openContextMenu = (event: MouseEvent, notification: Notification) => {
  event.preventDefault();
  contextNotification.value = notification;
  contextMenuStyle.value = { top: `${event.clientY}px`, left: `${event.clientX}px` };
  showContextMenu.value = true;
};
// Function to mark the notification as read
const markAsRead = async (notification: Notification) => {
  if (notification && !notification.isRead) {
    await notificationStore.markNotificationAsRead(notification.id);
  }
  selectedNotification.value = notification;
  showContextMenu.value = false;
};
// Function to mark the notification as unread
const markAsUnread = async (notification: Notification) => {
  if (notification && notification.isRead) {
    await notificationStore.markNotificationAsUnread(notification.id);
  }
  showContextMenu.value = false;
};

// Hide the context menu if the user clicks outside
const closeContextMenu = () => {
  showContextMenu.value = false;
};

// Fetch notifications when the component is mounted
onMounted(() => {
  notificationStore.fetchNotifications();
});
</script>

<template>
  <UDashboardSlideover v-model="isNotificationsSlideoverOpen" title="Notifications">
    <!-- Show loading message while notifications are being fetched -->
    <div v-if="loading">Loading...</div>
    <!-- Show message if no notifications are available -->
    <div v-else-if="notifications.length === 0">No notifications found.</div>
    <!-- Iterate over and display each notification -->
    <div v-else v-for="notification in notifications" :key="notification.id"
         @click="markAsRead(notification)"
         @contextmenu.prevent="openContextMenu($event, notification)"
         :class="{ 'bg-gray-100 dark:bg-gray-700': selectedNotification === notification }"
         class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative">
      <!-- If the notification is a link, wrap it in a NuxtLink component -->
      <NuxtLink v-if="notification.type === 'link'" :to="notification.url" class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative">
        <!-- Show a yellow dot if the notification has been read -->
        <div v-if="!notification.isRead" class="w-3 h-3 rounded-full bg-yellow-700"></div>
        <p>{{ notification.message }}</p>
      </NuxtLink>
      <!-- If the notification is not a link, just display the message -->
      <div v-else class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative">
        <div v-if="!notification.isRead" class="w-3 h-3 rounded-full bg-yellow-700"></div>
        <p>{{ notification.message }}</p>
      </div>
    </div>
     <!-- Custom Context Menu -->
     <div v-if="showContextMenu" :style="contextMenuStyle" class="fixed z-50 bg-white shadow-lg rounded-md p-2 bg-gray-50 dark:bg-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800">
      <button v-if="contextNotification?.isRead" 
        @click="contextNotification && markAsUnread(contextNotification)" 
        class="block w-full text-left p-2 hover:bg-gray-100 dark:hover:bg-gray-800">
        Mark as Unread
      </button>
      <button v-else 
        @click="contextNotification && markAsRead(contextNotification)" 
        class="block w-full text-left p-2 hover:bg-gray-100 dark:hover:bg-gray-800">
        Mark as Read
      </button>
    </div>

    <!-- Capture click outside to close the context menu -->
    <div v-if="showContextMenu" @click="closeContextMenu" class="fixed inset-0 z-40"></div>
  </UDashboardSlideover>
</template>
