<script setup lang="ts">
import { useNotificationStore } from '~/stores/notification';
import { storeToRefs } from 'pinia';

const notificationStore = useNotificationStore();
const { unreadCount } = storeToRefs(notificationStore);
const { isNotificationsSlideoverOpen } = useDashboard();

onMounted(() => {
  notificationStore.startPolling();
});

onUnmounted(() => {
  notificationStore.stopPolling();
});

const toggleNotifications = () => {
  isNotificationsSlideoverOpen.value = true;
};
</script>

<template>
  <UButton color="gray" variant="ghost" square @click="toggleNotifications">
    <div class="notification-badge">
      <UIcon name="i-heroicons-bell" class="w-6 h-6" />
      <!-- Show the counter if there are unread notifications -->
      <div v-if="unreadCount > 0" class="notification-count">
        <span>{{ unreadCount > 99 ? '99+' : unreadCount }}</span>
      </div>
    </div>
  </UButton>
</template>

<style scoped>
.notification-badge {
  position: relative;
  display: inline-block;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
}

.notification-count span {
  line-height: 1;
}
</style>
